import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import HomeIcon from "./../../assets/images/games-icon/headicon/house.png";
import GamesRuleIcon from "./../../assets/images/icons/Gold-Shine-PNG-Image.png";
import ChallengesIcon from "./../../assets/images/games-icon/cashier.png";
import CricketIcon from "./../../assets/images/games-icon/cricket-icon1.png";
import SoccerIcon from "./../../assets/images/games-icon/Soccer.png";
import TennisIcon from "./../../assets/images/games-icon/Tennis.png";

const LeftBarSports = () => {
  const navigate = useNavigate();
  const { sportsData, oddsData, showBetSlip } = useSelector(
    (state) => state.sports
  );
  const { isAuth } = useSelector((state) => state.auth);

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const tabs = [
    {
      id: 1,
      eventKey: "1",
      name: "In play",
    },
    {
      id: 2,
      eventKey: "2",
      name: "Cricket",
    },
    {
      id: 3,
      eventKey: "3",
      name: "Soccer",
    },
    {
      id: 4,
      eventKey: "4",
      name: "Tennis",
    },
  ];

  return (
    <>
      <div className="leftbarSec">
        <ul>
          <li className="games_link">
            <ul>
              <li>
                <div
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  <img src={HomeIcon} alt="home" />
                  <span>Home</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sports/Cricket");
                  }}
                >
                  <img src={CricketIcon} alt="cricket" />
                  <span>Cricket</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sports/Soccer");
                  }}
                >
                  <img src={SoccerIcon} alt="soccer" />
                  <span>Soccer</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigate("/sports/Tennis");
                  }}
                >
                  <img src={TennisIcon} alt="tennis" />
                  <span>Tennis</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    if (isAuth) {
                      navigate("/rulesandregulations");
                    } else {
                      navigate("/rulesandregulationss");
                    }
                  }}
                >
                  <img src={GamesRuleIcon} alt="games rule" />
                  <span>Rules & Regulations</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    if (isAuth) {
                      navigate("/challenges");
                    } else {
                      navigate("/challenges");
                    }
                  }}
                >
                  <img src={ChallengesIcon} alt="challenges" />
                  <span>Challenges</span>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LeftBarSports;
//

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import { Button, Offcanvas, Modal, Form } from "react-bootstrap";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import MenuIcon1 from "../assets/images/icons/menuicon.png";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import SettingIcon from "../assets/svg/SettingIcon";
import logo from "../assets/svg/logo_new.png";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import numberWithCommas from "../utils/numbersWithComma";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";
import ExchangeFundsFormPopUp from "../views/components/ExchangeFundsFormPopUp";
// import Index from "../views/pages/privatePages/exchangepopup";
import { getExposureAmount } from "../redux/auth/actions";
import { getBetMatches, getUserBet } from "../redux/sports/actions";

import TextIcon from "../assets/images/icons/text-size.png";
import WhatsappIcon1 from "../assets/images/icons/whatsapp.png";
import BonusIcon from "../assets/images/icons/gift.png";
import SportsIcon1 from "../assets/images/icons/Sports-white.png";
import SearchIcon from "../assets/images/icons/find.png";
import WalletIcon1 from "../assets/images/icons/depositnew.png";
import WalletIcon2 from "../assets/images/icons/withdraw.png";

import BonusIcon1 from "../assets/images/icons/Bonus.png";
import AccountStatement1 from "../assets/images/icons/Account-setting.png";
import ReferralIcon1 from "../assets/images/icons/withdraw.png";
import MyBets1 from "../assets/images/icons/Account-statement.png";
import SignOut1 from "../assets/images/icons/signout.png";
import ProfileIcon1 from "../assets/images/icons/Profile.png";
import { handleRightSideBar } from "../redux/app/actions";

import FetchingBal from "../assets/images/icons/fetch.gif";
import refferal from "../assets/images/icons/Reffral.png";
import { AiOutlineClose } from "react-icons/ai";
import StarIcon from "../assets/images/icons/Gold-Shine-PNG-Image.png";
import Promotionicon from "../assets/images/icons/Promotion.png";
import AppDownload from "../assets/images/icons/download.png";
import BackArrow from "../assets/images/icons/back-arrow.png";

const HeaderAfterLogin = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { user, wallet, exposureAmount } = useSelector((state) => state.auth);
  const isBonus = numberWithCommas(wallet?.coins) || 0;
  const { betMatches, userBets } = useSelector((state) => state.sports);
  const { showRightSideBar } = useSelector((state) => state.app);
  const [showBalance, setShowBalanceLoading] = useState(false);

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    handleSideBarVisibility(false);
    setShow(false);
  };
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showexchangeModal, setShowexchangeModal] = useState(false);
  const [showBetModal, setShowBetModal] = useState(false);
  const [showUserBetModal, setShowUserBetModal] = useState(false);
  const [visible, setVisible] = useState(true);
  const [fontConfig, setFontConfig] = useState({
    counter: 0,
    fontSize: 12,
    type: "inc",
  });
  const isApk = localStorage.getItem("isApk") === "true";
  const [showToggle, setShowToggle] = useState(true);

  useEffect(() => {
    if (showToggle) {
      document.body.classList.add("header_open");
    } else {
      document.body.classList.remove("header_open");
    }
  }, [showToggle]);
  useEffect(() => {
    // dispatch(getExposureAmount());
    // dispatch(getBetMatches({ status: "pending" }));
    setShowToggle(window.screen.width > 992);
    let intervalID = setInterval(() => {
      setShowBalanceLoading((p) => !p);
    }, 5000);
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  const handleRowClick = (item) => {
    dispatch(
      getUserBet({
        skip: 0,
        pageSize: 10,
        refmatchid: item?.refMatchId,
      })
    );
    setShowBetModal(false);
    setShowUserBetModal(true);
  };

  const [showLightModeToggle, setShowLightModeToggle] = useState(false);
  useEffect(() => {
    if (showLightModeToggle) {
      document.body.classList.add("light-mode");
    } else {
      document.body.classList.remove("light-mode");
    }
  }, [showLightModeToggle]);

  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  useEffect(() => {
    if (showLeftbarToggle) {
      document.body.classList.add("leftbat-opened");
    } else {
      document.body.classList.remove("leftbat-opened");
    }
  }, [showLeftbarToggle]);

  const handleSideBarVisibility = (value) => {
    dispatch(handleRightSideBar(value));
  };
  const handleFontConfig = () => {
    if (fontConfig.counter <= 2 && fontConfig.type == "inc") {
      if (fontConfig.counter === 2) {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter - 1,
          fontSize: p.fontSize - 2,
          type: "dec",
        }));
      } else {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter + 1,
          fontSize: p.fontSize + 2,
        }));
      }
    } else if (fontConfig.counter >= 0 && fontConfig.type == "dec") {
      if (fontConfig.counter === 0) {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter + 1,
          fontSize: p.fontSize + 2,
          type: "inc",
        }));
      } else {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter - 1,
          fontSize: p.fontSize - 2,
        }));
      }
    }
  };

  const renderBalance = () => {
    return (
      <>
        <div className="cashloader">
          <div className="balance-web">
            <ul>
              <li>
                Cash: <span>{numberWithCommas(wallet?.cash || 0)}</span>
              </li>
              {isBonus != 0 && (
                <li>
                  Coins:
                  <span> {numberWithCommas(wallet?.coins)}</span>
                  {/* <span> {numberWithCommas(exposureAmount || 0)}</span> */}
                </li>
              )}
              {/* <li>
                Sports: <span> {numberWithCommas(exposureAmount || 0)}</span>
              </li> */}
            </ul>
          </div>
          {/* <p>
            //{" "}
            <span>
              // <img src={FetchingBal} />
              // Fetching Balance... //{" "}
            </span>
            //{" "}
          </p> */}
        </div>
      </>
    );
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const offcanvas = document.querySelector(".offcanvas");

      if (showRightSideBar && offcanvas && !offcanvas.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showRightSideBar]);

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      {/* {visible ? (
        <div className="marqu">
          <marquee>
            <img src={StarIcon} alt="star icon" />
            Get 3% direct CASH bonus on every UPI deposit.
            <img src={StarIcon} alt="star icon" />
          </marquee>
          <span onClick={() => setVisible(!visible)}>
            <AiOutlineClose />
          </span>
        </div>
      ) : (
        ""
      )} */}
      <header className="header aftrlgn">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center afterloginsection">
                  <div className="leftbar_toggle d-none ">
                    <Button
                      variant="primary"
                      onClick={() => setShowLeftbarToggle((s) => !s)}
                    >
                      <img src={MenuIcon1} />
                    </Button>
                  </div>
                  <div className="headerRight">
                    {/* <a href="/promotion" className="headicons">
                      <img src={Promotionicon} /> Promotion
                    </a> */}
                    {window.location.pathname !== "/home" && (
                      <Button
                        variant=""
                        className="back_btn header_back d-md-none"
                        onClick={handleBackClick}
                      >
                        <img src={BackArrow} alt="back icon" />
                      </Button>
                    )}
                    <a
                      className="panel headicons d-block d-md-none"
                      href={
                        appDetails?.WHATS_APP_URL != ""
                          ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                          : "#"
                      }
                      target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                    >
                      <img src={WhatsappIcon1} />
                    </a>
                  </div>
                </div>
                <div className="logo">
                  <a href="/home">
                    <img src={appDetails?.LOGO_URL} alt="Logo" />
                  </a>
                </div>

                <div className="headerRight headerrightsec">
                  <div className="searchbar d-none">
                    <Form>
                      <Form.Group className="position-relative">
                        <Form.Control
                          type="text"
                          placeholder="Search your favourite games"
                        />
                        <span>
                          <img src={SearchIcon} />
                        </span>
                      </Form.Group>
                    </Form>
                  </div>
                  {/* <a href="/deposit" className="deposit primary">
                    Deposit
                  </a> */}
                  {/* <div className="headicons" onClick={handleFontConfig}>
                    <img src={TextIcon} className="texticon" />
                  </div> */}
                  {/* <a href="/betby" className="headicons">
                    <img src={SportsIcon1} /> Sports
                  </a> */}
                  <div className="depositbtn">
                    <a href="/gatewaylist" className="headicons">
                      <img src={WalletIcon1} />{" "}
                      <span className="d-none d-xl-block">Deposit</span>
                      <span className="d-xl-none">D</span>
                    </a>
                    <a href="/withdraw" className="headicons">
                      <img src={WalletIcon2} />{" "}
                      <span className="d-none d-xl-block">Withdraw</span>
                      <span className="d-xl-none">W</span>
                    </a>
                  </div>

                  {(!isApk && (
                    <a
                      href="https://dl.dropbox.com/scl/fi/b19o6sq27ekbuv6re0w36/Daddy11.apk?rlkey=nq2c2tqzchu83p32zm1xxetus&dl=0"
                      download=""
                      className="headicons support px-0"
                    >
                      <img src={AppDownload} /> App
                    </a>
                  )) ||
                    null}

                  <a
                    className="panel headicons d-none d-md-block"
                    href={
                      appDetails?.WHATS_APP_URL != ""
                        ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                        : "#"
                    }
                    target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                  >
                    <img src={WhatsappIcon1} className="me-0" />
                  </a>

                  <ul className="d-flex d-md-none mx-2 mob-whatsapp">
                    <li>
                      <a
                        className="panel"
                        href={
                          appDetails?.WHATS_APP_URL != ""
                            ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                            : "#"
                        }
                        target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                      >
                        <img src={WhatsappIcon1} />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src={SearchIcon} />
                      </a>
                    </li>
                  </ul>

                  {renderBalance()}

                  <Dropdown align="end" className="me-2 d-none ">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Bal: <span>{numberWithCommas(wallet?.cash || 0)}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="tel:{user?.mobileno}">
                        <b>{user?.mobileno}</b>
                      </Dropdown.Item>
                      <Dropdown.Item href="#">
                        <h6>Balance</h6>
                        <span>$ {numberWithCommas(wallet?.cash || 0)}</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => setShowBetModal(true)}
                      >
                        <h6>Exposure</h6>
                        <span>$ {numberWithCommas(exposureAmount || 0)}</span>
                      </Dropdown.Item>
                      {isBonus != 0 && (
                        <Dropdown.Item href="#">
                          <h6>Coins</h6>
                          <span>$ {numberWithCommas(wallet?.coins)}</span>
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item href="/gatewaylist" className="deposit">
                        Deposit
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <div className="dpst-wtdrw d-md-none">
                    <a href="/gatewaylist" className="deposit">
                      <span>D</span>
                    </a>
                    <a href="/withdraw" className="withdraw">
                      <span>W</span>
                    </a>
                  </div>

                  <div className="funds d-none">
                    <ul>
                      <li className="d-none">
                        <a href="/gatewaylist" className="deposit">
                          Deposit
                        </a>
                      </li>
                      <Dropdown
                        className="headerMainDropdown d-block d-md-none"
                        placement={"end"}
                      >
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="deposit"
                        >
                          Deposit
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="">
                            <div className="row">
                              <div className="col-6">
                                <div className="balance mb-1">Main Balance</div>
                              </div>
                              <div className="col-6">
                                <div className="balance-amount mb-1">
                                  {numberWithCommas(wallet?.cash || 0)}
                                </div>
                              </div>
                            </div>
                          </Dropdown.Item>
                          {isBonus != 0 && (
                            <Dropdown.Item href="">
                              <div className="row">
                                <div className="col-6">
                                  <div className="balance ">Bonus</div>
                                </div>
                                <div className="col-6">
                                  <div className="balance-amount">
                                    {numberWithCommas(wallet?.coins) || 0}
                                  </div>
                                </div>
                              </div>
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item href="">
                            <div
                              className="col-12 "
                              onClick={() => {
                                navigate("/withdraw");
                              }}
                            >
                              <Button variant="w-100" className="primary">
                                Withdraw
                              </Button>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item href="">
                            <div
                              className="col-12"
                              onClick={() => {
                                navigate("/gatewaylist");
                              }}
                            >
                              <Button variant="primary w-100">Deposit</Button>
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </ul>
                  </div>
                  <Button
                    variant="primary menu-btn "
                    className="d-none d-md-block"
                    onClick={() => handleSideBarVisibility(true)}
                  >
                    <img src={ProfileIcon1} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Offcanvas
          show={showRightSideBar}
          onHide={handleClose}
          placement={"end"}
          className="menuDropdown"
        >
          <Offcanvas.Header closeButton="closeButton" closeVariant="white">
            <Offcanvas.Title>
              <div className="d-flex">
                <div className="profile-img">
                  <img src={ProfileIcon1} />
                </div>
                <div className="user-info">
                  <span>{user?.mstruserid}</span>
                  <a href="tel:{user?.mobileno}">{user?.mobileno}</a>
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="row offcanvas-head">
              <div className="col-6">
                <div className="balance mb-1">Main Balance</div>
              </div>
              <div className="col-6">
                <div className="balance-amount mb-1">
                  {numberWithCommas(wallet?.cash || 0)}
                </div>
              </div>
              {isBonus != 0 && (
                <>
                  <div className="col-6">
                    <div className="balance mb-3">Coins</div>
                  </div>
                  <div className="col-6">
                    <div className="balance-amount mb-3">
                      {numberWithCommas(wallet?.coins) || 0}
                    </div>
                  </div>
                </>
              )}
              <div
                className="col-6 "
                onClick={() => {
                  navigate("/withdraw");
                }}
              >
                <Button variant="  w-100" className="btn-secondary">
                  Withdraw
                </Button>
              </div>
              <div
                className="col-6"
                onClick={() => {
                  navigate("/gatewaylist");
                }}
              >
                <Button variant="primary w-100">Deposit</Button>
              </div>
              {/* <div className="col-4">
                <Button variant="primary w-100" onClick={handleShowModal}>
                  Exchange
                </Button>
                <ExchangeFundsFormPopUp
                  showexchangeModal={showexchangeModal}
                  handleCloseModal={handleCloseModal}
                />
              </div> */}
            </div>
            <ul className="menu-items">
              <li>
                <a href="/profile">
                  <img src={ProfileIcon1} />
                  <span>Profile</span>
                </a>
              </li>
              <li>
                <a href="/mybets">
                  <img src={MyBets1} />
                  <span>My Bets</span>
                </a>
              </li>
              <li>
                <a href="/referral">
                  <img src={refferal} />
                  <span>Referral</span>
                </a>
              </li>
              {/* <li>
                  <a href="/promotion">
                    <img src={Promotionicon} />
                    <span>Promotion</span>
                  </a>
                </li> */}
              {/* <li>
                <a href="/bonus">
                  <img src={BonusIcon1} />
                  <span>Bonus</span>
                </a>
              </li>
              <li>
                <a href="/referral">
                  <img src={ReferralIcon1} />
                  <span>Referral</span>
                </a>
              </li> */}
              <li>
                <a href="/cashier">
                  <img src={AccountStatement1} />
                  <span>Account Statement</span>
                </a>
              </li>
              {/* <li>
                <a href="#">
                  <SettingIcon />
                  <span>Transfer Funds ( For Excahnge)</span>
                </a>
              </li> */}
              {/* <li>
                <a href="/settings">
                  <SettingIcon />
                  <span>Settings</span>
                </a>
              </li> */}
              {/* <li>
                <a href="/bonus" className="">
                  <img src={BonusIcon} /> Bonus
                </a>
              </li> */}
              <li
                onClick={() => {
                  dispatch(logoutUser());
                }}
              >
                <a href="/">
                  <img src={SignOut1} />
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>
            <ul className="social_links">
              <li>
                <a
                  href={appDetails.TELEGRAM != "" ? appDetails.TELEGRAM : "#"}
                  target={appDetails.TELEGRAM != "" ? "_blank" : ""}
                >
                  <TelegramIcon />
                </a>
              </li>
              {/* <li>
                <div
                  onClick={() =>
                    window.open(https://wa.me/+917888846060, "_blank")
                  }
                >
                  <WhatsappIcon />
                </div>
              </li> */}
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                      : "#"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <WhatsappIcon />
                </a>
              </li>
              <li className="theme-btn">
                <button
                  type="button"
                  onClick={() => setShowLightModeToggle((s) => !s)}
                >
                  <div className="theme-icon">
                    <img
                      src={MoonLight}
                      alt="Moon Icon"
                      className="dark_theme"
                    />
                    <img
                      src={SunLight}
                      alt="Sun Icon"
                      className="light_theme"
                    />
                  </div>
                </button>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>

        <Modal
          className="betslip_popup outer_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Exposure amount</th>
                      <th scope="col">Match name</th>
                      <th scope="col">Ref match ID</th>
                      <th scope="col">Total bets</th>
                    </tr>
                  </thead>
                  <tbody>
                    {betMatches &&
                      betMatches.length &&
                      betMatches.map((item, index) => {
                        return (
                          <tr
                            onClick={() => {
                              handleRowClick(item);
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <td>{item?.exposureAmount}</td>
                            <td>{item?.matchName}</td>
                            <td>{item?.refMatchId}</td>
                            <td>{item?.totalBets}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="betslip_popup inner_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showUserBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Odds</th>
                      <th scope="col">Stack</th>
                      <th scope="col">Selection type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Create date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userBets &&
                      userBets.length &&
                      userBets.map((item, index) => {
                        return (
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{item?.odds}</td>
                            <td>{item?.stack}</td>
                            <td>{item?.selectionType}</td>
                            <td>{item?.status}</td>
                            <td>{item?.createdAt}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowUserBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        <div className="bottom_head d-none">
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0">
                <ul style={{ fontSize: `${fontConfig.fontSize}px` }}>
                  {/* <li>
                    <a href="/betby">
                      <span>Sportsbook</span>
                    </a>
                  </li> */}
                  <li>
                    <a href="/sports/Cricket">
                      <span>Exchange</span>
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </header>
    </>
  );
};

export default HeaderAfterLogin;

import React from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Whatsapp from "../../../../assets/images/Whatsapp.svg";
import Telegram from "../../../../assets/images/Telegram.svg";
import Youtube from "../../../../assets/images/youtube.png";

import Instagram from "../../../../assets/images/Instagram.svg";
import { useSelector } from "react-redux";

import ContactUsImg from "../../../../assets/images/contact1.png";

const ContactUs = () => {
  const { appDetails } = useSelector((state) => state.app);

  return (
    <>
      <HeaderAfterLogin />

      <main className="main footer-content">
        <div className="container">
          <h2 className="sectionTitle mt-4">Contact Us</h2>
          <div className="payment-info contact-pay-info">
            <div className="contact-img">
              <img src={ContactUsImg} />
            </div>
            <div className="social-icons contact-social">
              <ul>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.WHATS_APP_URL != ""
                        ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                        : "javascript:void(0)"
                    }
                    target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                  >
                    {/* <span className="notification-count">2</span> */}
                    <img src={Whatsapp} alt="Whatsapp Icon" />
                    <p>Whats App</p>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      appDetails.TELEGRAM != ""
                        ? appDetails.TELEGRAM
                        : "javascript:void(0)"
                    }
                    target={appDetails.TELEGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Telegram} alt="Telegram Icon" />
                    <p> Telegram</p>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      appDetails.INSTATGRAM != ""
                        ? appDetails.INSTATGRAM
                        : "javascript:void(0)"
                    }
                    target={appDetails.INSTATGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Instagram} alt="Instagram Icon" />
                    <p>Instagram</p>
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href="https://youtube.com/@DADDY11OFFICIAL?si=otVGhWxn_73QLpoc"
                    target="_blank"
                  >
                    {" "}
                    <img src={Youtube} alt="Whatsapp Icon" />
                    <p>Youtube</p>
                    {/* <span className="notification-count">2</span> */}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default ContactUs;

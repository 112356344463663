import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Offcanvas, Form } from "react-bootstrap";

import EyeIcon from "../assets/svg/EyeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import logo from "../assets/svg/logo_new.png";
import WhatsappIconNew from "../assets/images/social/whatsapp.png";
import TelegramIconNew from "../assets/images/social/telegram.png";
import SportsIcon1 from "../assets/images/icons/Sports-white.png";
import TextIcon from "../assets/images/icons/text-size.png";
import WhatsappIcon1 from "../assets/images/icons/whatsapp.png";
import BonusIcon from "../assets/images/icons/gift.png";
import SearchIcon from "../assets/images/icons/find.png";
import { AiOutlineClose } from "react-icons/ai";
import StarIcon from "../assets/images/icons/Gold-Shine-PNG-Image.png";
import Promotionicon from "../assets/images/icons/Promotion.png";
import AppDownload from "../assets/images/icons/download.png";
import MenuToggle from "../assets/images/icons/MenuToggle.png";
import HomeMenu from "../assets/images/icons/Home.png";
import InPlayMenu from "../assets/images/icons/In-play.png";
import CricketMenu from "../assets/images/icons/Cricket.png";
import SoccerMenu from "../assets/images/icons/Soccer.png";
import TennisMenu from "../assets/images/icons/Tennis.png";
import horseracingMenu from "../assets/images/icons/Horseracing.png";
import BasketballMenu from "../assets/images/icons/Basketball.png";
import IceHockeyMenu from "../assets/images/icons/IceHockey.png";
import GreyhoundRacingMenu from "../assets/images/icons/GreyhoundRacing.png";
import PoliticsMenu from "../assets/images/icons/Politics.png";
import MyBetsMenu from "../assets/images/icons/MyBets.png";
import BonusMenu from "../assets/images/icons/Bonus.png";
import ReferralMenu from "../assets/images/icons/Referral.png";
import AccountStatementMenu from "../assets/images/icons/AccountStatement.png";
import { REFERRAL_CODES } from "../config/const";

const Header = () => {
  //test
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const navigate = useNavigate();
  const isApk = localStorage.getItem("isApk") === "true";
  const { appDetails } = useSelector((state) => state.app);

  return (
    <>
      <header className="header beforeheader">
        <div className="top_head">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="headerRight">
                    <div className="searchbar d-none">
                      <Form>
                        <Form.Group className="position-relative">
                          <Form.Control
                            type="text"
                            placeholder="Search your favourite games"
                          />
                          <span>
                            <img src={SearchIcon} />
                          </span>
                        </Form.Group>
                      </Form>
                    </div>
                  </div>
                  <div className="logo">
                    <a href="/">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                    </a>
                  </div>

                  <div className="headerRight headerrightsec">
                    <a href="/sign-in" className="headicons">
                      <img src={SportsIcon1} /> Sports
                    </a>
                    {(!isApk && (
                      <a
                        href="https://dl.dropbox.com/scl/fi/b19o6sq27ekbuv6re0w36/Daddy11.apk?rlkey=nq2c2tqzchu83p32zm1xxetus&dl=0"
                        download=""
                        className="headicons support"
                      >
                        <img src={AppDownload} /> App
                      </a>
                    )) ||
                      null}
                    {/* <a href="/sign-in" className="headicons">
                      <img src={BonusIcon} /> Bonus
                    </a> */}
                    <a
                      className="panel headicons"
                      href={
                        appDetails?.WHATS_APP_URL != ""
                          ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                          : "#"
                      }
                      target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                    >
                      <img src={WhatsappIcon1} />
                    </a>
                    <ul className="social d-none">
                      <li>
                        <a
                          href={
                            appDetails?.TELEGRAM != ""
                              ? appDetails?.TELEGRAM
                              : "#"
                          }
                          target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                        >
                          <img src={TelegramIconNew} />
                        </a>
                      </li>
                      <li>
                        <a
                          className="panel"
                          href={
                            appDetails?.WHATS_APP_URL != ""
                              ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                              : "#"
                          }
                          target={
                            appDetails?.WHATS_APP_URL != "" ? "_blank" : ""
                          }
                        >
                          <img src={WhatsappIconNew} />
                        </a>
                      </li>
                    </ul>
                    <ul className="d-flex d-md-none mx-2">
                      <li>
                        <a
                          className="panel"
                          href={
                            appDetails?.WHATS_APP_URL != ""
                              ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                              : "#"
                          }
                          target={
                            appDetails?.WHATS_APP_URL != "" ? "_blank" : ""
                          }
                        >
                          <img src={WhatsappIcon1} />
                        </a>
                      </li>
                      {/* <li>
                        <a href="#">
                          <img src={SearchIcon} />
                        </a>
                      </li> */}
                    </ul>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate("/sign-in");
                      }}
                      className="button-primary btn_signin"
                    >
                      Log In
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate("/mobile-number");
                      }}
                      className="button-primary btn_signup btn-secondary ms-2"
                    >
                      Register
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Offcanvas show={show} onHide={handleClose} className="bfrLgnLeftMenu">
          {/* <Offcanvas.Header closeButton>
            <Offcanvas.Title>Offcanvas</Offcanvas.Title>
          </Offcanvas.Header> */}
          <Offcanvas.Body>
            <ul>
              <li className="games_link">
                <h6>Quick Links</h6>
                <ul>
                  <li>
                    <a href="/">
                      <img src={HomeMenu} alt="" />
                      <span>Home</span>
                    </a>
                  </li>
                  <li>
                    <a href="/sign-in">
                      <img src={InPlayMenu} alt="" />
                      <span>In-play</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li className="games_link">
                <h6>Sports</h6>
                <ul>
                  <li>
                    <a href="/sign-in">
                      <img src={CricketMenu} alt="" />
                      <span>Cricket</span>
                    </a>
                  </li>
                  <li>
                    <a href="/sign-in">
                      <img src={SoccerMenu} alt="" />
                      <span>Soccer</span>
                    </a>
                  </li>
                  <li>
                    <a href="/sign-in">
                      <img src={TennisMenu} alt="" />
                      <span>Tennis</span>
                    </a>
                  </li>
                  <li>
                    <a href="/sign-in">
                      <img src={horseracingMenu} alt="" />
                      <span>Horseracing</span>
                    </a>
                  </li>
                  <li>
                    <a href="/sign-in">
                      <img src={BasketballMenu} alt="" />
                      <span>Basketball</span>
                    </a>
                  </li>
                  <li>
                    <a href="/sign-in">
                      <img src={IceHockeyMenu} alt="" />
                      <span>Ice Hockey</span>
                    </a>
                  </li>
                  <li>
                    <a href="/sign-in">
                      <img src={GreyhoundRacingMenu} alt="" />
                      <span>Greyhound Racing</span>
                    </a>
                  </li>
                  <li>
                    <a href="/sign-in">
                      <img src={PoliticsMenu} alt="" />
                      <span>Politics</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li className="games_link">
                <h6>My Account</h6>
                <ul>
                  <li>
                    <a href="/sign-in">
                      <img src={MyBetsMenu} alt="" />
                      <span>My Bets</span>
                    </a>
                  </li>
                  <li>
                    <a href="/sign-in">
                      <img src={BonusMenu} alt="" />
                      <span>Bonus</span>
                    </a>
                  </li>
                  <li>
                    <a href="/sign-in">
                      <img src={ReferralMenu} alt="" />
                      <span>Referral</span>
                    </a>
                  </li>
                  <li>
                    <a href="/sign-in">
                      <img src={AccountStatementMenu} alt="" />
                      <span>Account Statement</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>

        <div className="bottom_head">
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0">
                <ul>
                  {REFERRAL_CODES.map((referral, idx) => (
                    <li id={idx}>
                      <a
                        href={`/mobile-number?agent_code=${referral.referralCode}`}
                      >
                        <span>{referral.referralText}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Sign in now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                 Login with OTP
                </a>
              </div>
              <Button variant="primary" type="submit">
                Sign in
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Sign Up Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
      </header>
    </>
  );
};

export default Header;
